import { Typography, Grid, Card, CardContent, CardActions, Button, Container, Box } from '@mui/material';
import { motion } from 'framer-motion';

const Projects = () => {
  const projects = [
    {
      title: 'Todo App',
      description: 'A React-based todo application',
      url: '/todo-app',
      tech: ['React', 'Material-UI'],
      github: 'https://github.com/yourusername/todo-app'
    },
    {
      title: 'Project Name',
      description: 'Description of your new project',
      url: '/project-name',
      tech: ['React', 'Your-Technologies'],
      github: 'https://github.com/yourusername/project-name'
    },
    // Add more projects
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="content-container"
    >
      <Container>
        <Typography variant="h2" className="gradient-text" gutterBottom>
          My Projects
        </Typography>
        <Grid container spacing={3}>
          {projects.map((project, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card 
                component={motion.div}
                whileHover={{ y: -5 }}
                sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h5" gutterBottom>
                    {project.title}
                  </Typography>
                  <Typography color="text.secondary" paragraph>
                    {project.description}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 2 }}>
                    {project.tech.map((tech, i) => (
                      <Typography
                        key={i}
                        variant="caption"
                        sx={{
                          bgcolor: 'background.paper',
                          px: 1,
                          py: 0.5,
                          borderRadius: 1,
                        }}
                      >
                        {tech}
                      </Typography>
                    ))}
                  </Box>
                </CardContent>
                <CardActions>
                  <Button 
                    href={project.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="primary"
                  >
                    Live Demo
                  </Button>
                  <Button
                    href={project.github}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="secondary"
                  >
                    GitHub
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </motion.div>
  );
};

export default Projects; 