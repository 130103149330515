import { Typography, Box, Container } from '@mui/material';
import { motion } from 'framer-motion';

const Home = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="content-container"
    >
      <Container>
        <Box sx={{ mt: 8, mb: 4 }}>
          <Typography variant="h1" className="gradient-text" gutterBottom>
            Welcome to rikko.cool
          </Typography>
          <Typography variant="h5" color="text.secondary">
            A showcase of my React projects and experiments
          </Typography>
        </Box>
      </Container>
    </motion.div>
  );
};

export default Home; 